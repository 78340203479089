<script>
export default {
  name: "jiancemoban",
  data: () => {
    return {
      data_list: [],
      column_table: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      range_info: {"row": [0, 0], "column": [0, 0]},
      data_list_visible: false,
      tpl_data_visible: false,
      data_type: 0,
      data_type_options: [],
      options: {
        container: 'luckysheet', //luckysheet为容器id
        lang: 'zh',
        title: '',
        showinfobar: false,
        showtoolbar: false,
        showtoolbarConfig: {
          undoRedo: true, //撤销重做，注意撤消重做是两个按钮，由这一个配置决定显示还是隐藏
          font: true, // '字体'
          fontSize: true, // '字号大小'
          bold: true, // '粗体 (Ctrl+B)'
          italic: true, // '斜体 (Ctrl+I)'
          //strikethrough: true, // '删除线 (Alt+Shift+5)'
          underline: true, // '下划线 (Alt+Shift+6)'
          textColor: true, // '文本颜色'
          fillColor: true, // '单元格颜色'
          border: true, // '边框'
          image: true,
          mergeCell: true, // '合并单元格'
          horizontalAlignMode: true, // '水平对齐方式'
          verticalAlignMode: true, // '垂直对齐方式'
          print: false, // '打印'
        },
        column: 50,
        row: 50,
        showstatisticBar: false,
        enableAddRow: false,
        enableAddBackTop: false,
        userInfo: false,
        showsheetbar: false,
        cellRightClickConfig: {
          copy: true, // 复制
          copyAs: false, // 复制为
          paste: true, // 粘贴
          insertRow: true, // 插入行
          insertColumn: true, // 插入列
          deleteRow: true, // 删除选中行
          deleteColumn: false, // 删除选中列
          deleteCell: false, // 删除单元格
          hideRow: true, // 隐藏选中行和显示选中行
          hideColumn: false, // 隐藏选中列和显示选中列
          rowHeight: true, // 行高
          columnWidth: true, // 列宽
          clear: false, // 清除内容
          matrix: false, // 矩阵操作选区
          sort: false, // 排序选区
          filter: false, // 筛选选区
          chart: false, // 图表生成
          image: true, // 插入图片
          link: false, // 插入链接
          data: false, // 数据验证
          cellFormat: false // 设置单元格格式
        },
        sheetFormulaBar: false
      },
    }
  },
  mounted() {
    this.mountedDo();
  },
  methods: {
    mountedDo() {
      this.getDataTypeList();
    },
    saveTplData() {
      // 保存核酸检测模板动态数据
      this.$sa0.post({
        url: this.$api('保存核酸检测模板动态数据'),
        data: {
          range: JSON.stringify(this.range_info),
          value: this.data_type,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let mark = this.data_type_options[this.data_type].label;
            luckysheet.setCellValue(this.range_info.row[0], this.range_info.column[0], {
              m: `[@@ ${mark}]`,
              v: `[@@ ${mark}]`,
            })
            this.saveHesuanTpl()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getDataTypeList() {
      // 核酸检测模板动态数据类型
      this.$sa0.post({
        url: this.$api('核酸检测模板动态数据类型'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.data_type_options = response.data.list
            this.getHesuanTpl();
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getHesuanTpl() {
      // 获取核酸检测模板
      this.$sa0.post({
        url: this.$api('获取核酸检测模板'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let table = response.data.table
            this.pushExcelJson(table ? JSON.parse(table) : false);
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    dateRangeName() {
      let t1 = `${this.column_table[this.range_info.column[0]]}${this.range_info.row[0] + 1}`;
      let t2 = `${this.column_table[this.range_info.column[1]]}${this.range_info.row[1] + 1}`;
      if (t1 == t2) {
        return t1;
      } else {
        return `${t1}-${t2}`;
      }
    },
    saveHesuanTpl() {
      // 保存核酸检测模板
      let this_ = this;
      let sheet = luckysheet.toJson()
      this_.$sa0.post({
        url: this_.$api('保存核酸检测模板'),
        data: {
          content: JSON.stringify(sheet)
        }
      }).then((response) => {
        this_.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('保存成功')
            this.onTplDataClose();
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    uploadExcel(e) {
      let files = e.target.files;
      if (files == null || files.length == 0) return layer.msg('请上传文件')
      let name = files[0].name;
      let suffixArr = name.split("."), suffix = suffixArr[suffixArr.length - 1];
      if (suffix != "xlsx") return layer.msg('请上传xlsx文件')
      let this_ = this
      LuckyExcel.transformExcelToLucky(files[0], function (exportJson, luckysheetfile) {
            if (exportJson.sheets == null || exportJson.sheets.length == 0) {
              return layer.msg('请上传xlsx文件')
            }
            this_.pushExcelJson({data: exportJson.sheets});
          }
      )
    },
    pushExcelJson(exportJson = false) {
      let options = this.options
      if (exportJson) {
        options.data = exportJson.data
      }
      luckysheet.destroy();
      luckysheet.create(options);
    },
    setDataClick() {
      let range = luckysheet.getRange()
      this.range_info = range[0]
      // 获取核酸检测模板动态数据
      this.$sa0.post({
        url: this.$api('获取核酸检测模板动态数据'),
        data: {
          range: JSON.stringify(this.range_info),
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let data = response.data.info;
            if (data) {
              this.data_type = data.value;
            } else {
              this.data_type = 0;
            }
            this.tpl_data_visible = true;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    chooseThisData() {
      this.saveTplData();
    },
    onDataListClose() {
      this.data_list_visible = false
    },
    onTplDataClose() {
      this.tpl_data_visible = false
    },
    dateRangeNameForLine(range) {
      let t1 = `${this.column_table[range.column[0]]}${range.row[0] + 1}`;
      let t2 = `${this.column_table[range.column[1]]}${range.row[1] + 1}`;
      if (t1 == t2) {
        return t1;
      } else {
        return `${t1}-${t2}`;
      }
    },
    saveDataLineItemClick(item) {
      this.$sa0.post({
        url: this.$api('保存核酸检测模板动态数据'),
        data: {
          range: JSON.stringify(item.range_turn),
          value: item.value,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let mark = this.data_type_options[item.value].label;
            luckysheet.setCellValue(item.range_turn.row[0], item.range_turn.column[0], {
              m: `[@@ ${mark}]`,
              v: `[@@ ${mark}]`,
            })
            this.saveHesuanTpl()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    dataListShow() {
      // 获取核酸检测模板动态数据列表
      this.$sa0.post({
        url: this.$api('获取核酸检测模板动态数据列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.data_list = response.data.list
            this.data_list_visible = true;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    delThisData(item) {
      // 删除核酸检测模板动态数据
      this.$sa0.post({
        url: this.$api('删除核酸检测模板动态数据'),
        data: {
          range: JSON.stringify(item.range_turn),
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            luckysheet.setCellValue(item.range_turn.row[0], item.range_turn.column[0], {
              m: '',
              v: '',
            })
            this.saveHesuanTpl()
            this.dataListShow();
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
  },
}
</script>
<template>
  <div>
    <a-drawer
        width="900px"
        title="动态数据列表"
        placement="right"
        :closable="false"
        :visible="data_list_visible"
        @close="onDataListClose"
    >
      <div>
        <table class="layui-table">
          <colgroup>
            <col width="150">
            <col width="200">
            <col>
          </colgroup>
          <thead>
          <tr>
            <th>单元格</th>
            <th>数据</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,key) in data_list" :key="key">
            <td>{{ dateRangeNameForLine(item.range_turn) }}</td>
            <td>
              <a-select class="w-full" v-model="item.value">
                <a-select-option v-for="(i ,k) in data_type_options" :key="k" :value="i.value">
                  {{ i.label }}
                </a-select-option>
              </a-select>
            </td>
            <td>
              <a-space>
                <a-button type="primary" size="small" @click="saveDataLineItemClick(item)">保存</a-button>
                <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="delThisData(item)">
                  <template slot="title">
                    <p>确定删除该动态数据？</p>
                  </template>
                  <a-button type="danger" size="small">删除</a-button>
                </a-popconfirm>

              </a-space>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </a-drawer>

    <a-drawer
        width="500px"
        title="设置数据"
        placement="right"
        :closable="false"
        :visible="tpl_data_visible"
        @close="onTplDataClose"
    >
      <div>
        <div class="mt-10">
          <div class="mt-10">
            <div>{{ range_info }}</div>
            <b>选中的表格：</b>
            {{ dateRangeName() }}
          </div>
          <div class="mb-10 mt-3">
            <a-select class="w-full" v-model="data_type">
              <a-select-option v-for="(i ,k) in data_type_options" :key="k" :value="i.value">
                {{ i.label }}
              </a-select-option>
            </a-select>
          </div>
          <a-space>
            <a-button type="primary" @click="chooseThisData()">确定</a-button>
            <a-button type="primary" @click="onTplDataClose()">关闭</a-button>
          </a-space>
        </div>
      </div>
    </a-drawer>

    <div>
      <a-space>
        <div class="layui-btn upload_button_box_wrapper">
          上传模板
          <input type="file" @change="uploadExcel" class="upload_input_wrapper">
        </div>
        <div @click="saveHesuanTpl()" class="layui-btn">保存模板</div>
        <div @click="setDataClick()" class="layui-btn">设置动态数据</div>
        <div @click="dataListShow()" class="layui-btn">动态数据列表</div>
      </a-space>
    </div>
    <div>
      <div id="luckysheet" class="luckysheet_wrapper"></div>
    </div>
  </div>
</template>
<style scoped>
.upload_input_wrapper {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  cursor: pointer;
}

.upload_button_box_wrapper {
  position: relative;
}

.luckysheet_wrapper {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  left: 0;
  top: 40px;
  bottom: 0;
}
</style>
